import React from 'react';
import {Nav} from "../../components";
import '../../style/track-order.scss'
import Footer from "../../components/footer";
import {Button} from "../../components/common";

const TrackOrder = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Turn your company’s old devices into cash'}
            darkNav={true}
        />

        <div className="pageContainer trackOrder">
            <section className={'text-section'}>
                <h1>Check order status</h1>
                <p className="sub-line color-waterloo">
                    Please use the order number you received via email
                </p>
            </section>

            <section>
                <div className="track-form">
                    <div className="input-group">
                        <label htmlFor="email">Your email address <span className="color-red">*</span></label>
                        <input type="email" name={'email'} id={'email'} placeholder={'Example: john@mail.com'}/>
                    </div>

                    <div className="input-group">
                        <label htmlFor="phone">Order number <span className="color-red">*</span></label>
                        <input type="email" name={'phone'} id={'phone'} placeholder={'123456789-123456'}/>
                    </div>

                    <Button
                        className={'primary filled long'}
                        label={'Check status'}
                        click={() => alert('Hey')}
                    />
                </div>
            </section>
        </div>

        <Footer />
    </div>
};

export default TrackOrder;